// Generated by Framer (6bc8cd1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["n8Lk63yJ9", "rCoffAHJ8"];

const variantClassNames = {n8Lk63yJ9: "framer-v-hfgts5", rCoffAHJ8: "framer-v-aihoxx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "n8Lk63yJ9", "Variant 2": "rCoffAHJ8"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; dateMobile?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "n8Lk63yJ9", dateMobile: Kbqs2gDo9 = "/  June 2022", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "n8Lk63yJ9", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-r7Koa", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-hfgts5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"n8Lk63yJ9"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({rCoffAHJ8: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBNZWRpdW0=", "--framer-font-family": "\"Altone Medium\", serif", "--framer-font-size": "20px", "--framer-letter-spacing": "1px", "--framer-line-height": "20px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "uppercase"}}>/  June 2022</motion.p></React.Fragment>} className={"framer-q8vsp"} data-framer-name={"Itcan Blogs / June 2022"} fonts={["CUSTOM;Altone Medium"]} layoutDependency={layoutDependency} layoutId={"hIuhs2zz3"} style={{"--extracted-r6o4lv": "var(--token-2b19e1f6-edbc-4dc5-9f91-98f5dcc224f4, rgb(130, 125, 152)) ", "--framer-paragraph-spacing": "0px"}} text={Kbqs2gDo9} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} transition={transition} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({rCoffAHJ8: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBNZWRpdW0=", "--framer-font-family": "\"Altone Medium\", serif", "--framer-font-size": "18px", "--framer-letter-spacing": "1px", "--framer-line-height": "20px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-transform": "uppercase"}}>/  June 2022</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-r7Koa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-r7Koa * { box-sizing: border-box; }", ".framer-r7Koa .framer-hfgts5 { height: 20px; overflow: visible; position: relative; width: 132px; }", ".framer-r7Koa .framer-q8vsp { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 132
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"rCoffAHJ8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Kbqs2gDo9":"dateMobile"}
 */
const Framerl9qvJqgzj: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerl9qvJqgzj;

Framerl9qvJqgzj.displayName = "Blog date";

Framerl9qvJqgzj.defaultProps = {height: 20, width: 132};

addPropertyControls(Framerl9qvJqgzj, {variant: {options: ["n8Lk63yJ9", "rCoffAHJ8"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Kbqs2gDo9: {defaultValue: "/  June 2022", displayTextArea: false, title: "Date Mobile", type: ControlType.String}} as any)

addFonts(Framerl9qvJqgzj, [{family: "Altone Medium", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/l9qvJqgzj:default", url: "assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf"}, url: new URL("assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf", import.meta.url).href}])